import React from "react"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import About from "../components/about"
import Projects from "../components/projects"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import "../styles/index.scss"

// legacy version 1 of the website deployed on version1 subdomain
const IndexPage = () => (
  <div className="main">
    <SEO title="Home" />
    <Navbar />
    <div id="home" className="landing">
      <heading>
        <h1>
          Hi,
          <br /> I'm Shehbaz Jafri
        </h1>
        <h3>FRONT-END DEVELOPER</h3>
        <div className="social-links">
          <div className="circle-icon">
            <a
              href="https://twitter.com/ShehbazJafri"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <div className="circle-icon">
            <a
              href="https://github.com/shehbazjafri/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
          <div className="circle-icon">
            <a
              href="https://www.linkedin.com/in/shehbaz-jafri"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="circle-icon">
            <a
              href="mailto:hey@shehbazjafri.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </div>
      </heading>
      <a
        href="https://drive.google.com/file/d/1ksuxmZYX5a20AMEtvLbFiHGlntHRrZbF/view?usp=sharing"
        className="download-resume bw-transition"
      >
        Download Resume
      </a>
      <div className="down-arrow">
        <FontAwesomeIcon size="2x" icon={faChevronDown} />
      </div>
    </div>
    <About />
    <Projects />
    <Footer />
  </div>
)

export default IndexPage
