import React from "react"
import "./card.scss"
import Img from "gatsby-image"
export default function Card({ index, image, project }) {
  return (
    <div className="card">
      <Img
        key={index}
        fluid={image}
        style={{ maxHeight: "150px" }}
        imgStyle={{ objectFit: "contain" }}
        alt="Screenshot of the project"
      />
      <div className="content">
        <span className="title">{project.title}</span>
        <p className="description">{project.description}</p>
      </div>

      <div className="actions">
        {project.codeLink ? (
          <a
            href={project.codeLink}
            rel="noopener noreferrer"
            target="_blank"
            className="bw-transition"
          >
            Code
          </a>
        ) : null}
        {project.liveLink ? (
          <a
            href={project.liveLink}
            rel="noopener noreferrer"
            target="_blank"
            className="bw-transition"
          >
            Live
          </a>
        ) : null}
      </div>
    </div>
  )
}
