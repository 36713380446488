import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Card from "./card"
import "./projects.scss"

export default function Projects() {
  const projects = [
    {
      featuredImg: "pomodoro.png",
      title: "Pomodoro Clock",
      description: "Pomodoro clock made with React.",
      codeLink: "https://github.com/shehbazjafri/pomodoro-clock",
      liveLink: "https://shehbazjafri.github.io/pomodoro-clock/",
    },
    {
      featuredImg: "d3-visualizations.png",
      title: "D3 Visualizations",
      description: "Graphs and Charts made with D3.js and React.",
      codeLink: "https://github.com/shehbazjafri/d3-visualizations",
      liveLink: "https://shehbazjafri.github.io/d3-visualizations/",
    },
    {
      featuredImg: "calculator.png",
      title: "Calculator",
      description:
        "Calculator made with JavaScript and React. Implemented calculation algorithm using Reverse Polish Notation.",
      codeLink: "https://github.com/shehbazjafri/simple-calculator",
      liveLink: "https://shehbazjafri.github.io/simple-calculator/",
    },
    {
      featuredImg: "portfolio.png",
      title: "This Site",
      description:
        "Portfolio site made with Gatsby. I will be keeping this site updated with new features and content. :)",
      codeLink: "",
      liveLink: "",
    },
    {
      featuredImg: "tree.png",
      title: "Tree Traversal Animations",
      description:
        "Animation of tree traversals (pre-order, post-order...) made with HTML, CSS, and vanilla JavaScript.",
      codeLink:
        "https://github.com/shehbazjafri/frontend-project/tree/master/Tree-Traversal",
      liveLink:
        "https://shehbazjafri.github.io/frontend-project/Tree-Traversal/traversal.html",
    },
    {
      featuredImg: "memory.png",
      title: "Memory Game",
      description: "Card Memory game made with HTML, CSS, and JavaScript.",
      codeLink: "https://github.com/shehbazjafri/memory_game",
      liveLink: "https://shehbazjafri.github.io/memory_game/",
    },
  ]

  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(filter: { relativeDirectory: { eq: "projects" } }) {
        nodes {
          id
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 150) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `)
  return (
    <div id="projects" className="project-section">
      <h1 className="section-title">Featured Projects</h1>
      <div className="project-cards">
        {projects.map((project, i) => {
          const img = data.images.nodes.filter(image => {
            return (
              image.childImageSharp.fluid.originalName === project.featuredImg
            )
          })
          return (
            <Card
              key={i}
              index={i}
              image={img[0].childImageSharp.fluid}
              project={project}
            />
          )
        })}
      </div>
    </div>
  )
}
