import React from "react"
import "./about.scss"
function About() {
  return (
    <div id="about" className="about">
      <div>
        <h3>About Me</h3>
        <p>
          I'm a front-end developer. I also like to dabble with UI/UX designing.
        </p>
        <p>
          I discovered programming through my love of video games as I got
          interested in learning the "under the hood" of it all. I pursued
          information technology and software engineering as my formal
          university education. During university, I got really interested in
          JavaScript, and since then I've been fully immersed with JavaScript
          and related frameworks. I have worked on Java and Node.JS back-end in
          the past, among other technologies, but recently I've been focusing
          mainly on front-end JavaScript/TypeScript, (sometimes Node.JS, as
          needed), working with frameworks such as React and Gatsby.
        </p>
        <p>
          Now, I aim to continuously learn and grow as a software engineer and
          contribute to the tech community. <br />
          You can follow me on{" "}
          <a
            href="https://twitter.com/ShehbazJafri"
            style={{ textDecoration: "underline" }}
          >
            Twitter
          </a>
          . I tweet about programming, things I create and things I learn. :)
        </p>
        {/* <p>
          I did an internship as a front-end engineer at a startup in the first
          half of 2019, and since then I've done freelance work for some time
          and I will be joining a small team as software engineer in November of
          2019.
        </p> */}
        {/* <p>
          I've been playing video games since a long time and somewhere I got
          really interested in learning about behind the scenes of it all and so
          I discovered programming. I had a brush with coding during schooling.
          I learnt very basic concepts of programming in C that were taught in
          school. I really loved it at that time and I was good at it.
        </p> */}
        {/* <p>
          And so, I have a bachelor's degree in Information Technology. I
          finished my Masters in 2019. While I got a strong background in
          computer science and software engineering during university, I got
          interested in JavaScript and building front-end.
        </p> */}
        {/* <p>
        Since beginning my journey
        </p> */}
      </div>
    </div>
  )
}

export default About
