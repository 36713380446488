import React from "react"
import "./navbar.scss"
export default function Navbar() {
  return (
    <nav>
      <ul>
        <li>
          <a href="#home">HOME</a>
        </li>
        <li>
          <a href="#about">ABOUT</a>
        </li>
        <li>
          <a href="#projects">PROJECTS</a>
        </li>
      </ul>
    </nav>
  )
}
